import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { withPreview } from "gatsby-source-prismic";
import { Helmet } from "react-helmet";

// Context
import { PageType } from "../components/context/page-type";

// Components
import { Gallery } from "../components/images/gallery";
import { SingleVideoEmbed } from "../components/videos/single-video-embed";

// Context
import { useColorContext } from "../components/context/color-context";

const Page = styled.div`
  margin: 40px 0 0 0;

  @media (max-width: 900px) {
    margin: 55px 0 0 0;
  }
`;

const ContentContainer = styled.div`
  & .full-width-text {
    & .text-container {
      grid-column: 1 / 7;

      & p {
        font-size: 40px;
        line-height: 52px;

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        @media (max-width: 768px) {
          font-size: 26px;
          line-height: 34px;
        }
      }
    }
  }

  & .single-image {
    & .single-image-container {
      grid-column: 2 / 6;

      @media (max-width: 768px) {
        grid-column: 1 / 7;
      }
    }
  }

  & .single-video {
    & .single-video-container {
      grid-column: 2 / 6;

      @media (max-width: 768px) {
        grid-column: 1 / 7;
      }
    }
  }

  & .text {
    & .text-container {
      grid-column: 2 / 6;

      @media (max-width: 768px) {
        grid-column: 1 / 7;
      }

      & .inner-text-container {
        max-width: 895px;
        margin: 0 auto;

        & .section-title {
          & h1 {
            font-size: 40px;
            line-height: 52px;

            @media (max-width: 768px) {
              font-size: 26px;
              line-height: 34px;
            }
          }
        }

        & .section-text {
          & p:first-of-type {
            margin-top: 0;
          }

          & p:last-of-type {
            margin-bottom: 0;
          }

          & ol {
            margin: 1em 0;

            & li {
              list-style: decimal;
              margin: 0 0 1em 20px;
              padding: 0 0 0 10px;

              &:last-of-type {
                margin: 0 0 0 20px;
              }
            }
          }
        }
      }
    }
  }

  & .gallery {
    margin: 0 0 70px 0;

    & > div {
      grid-column: 1 / 7;

      max-width: 900px;
      margin: 0 auto;
    }

    @media (max-width: 768px) {
      margin: 40px 0;
    }
  }

  & .related-projects {
    & .section-title {
      grid-column: 1 / 7;

      margin: 0 0 40px 0;

      @media (max-width: 768px) {
        margin: 0;

        & h3 {
          font-size: 18px;
          line-height: 23px;
        }
      }
    }

    & .related-projects-container {
      grid-column: 1 / 7;
      grid-row-gap: 35px;

      & article {
        grid-column: span 2;

        & .title {
          margin: 14px 0 0 0;
        }

        @media (max-width: 768px) {
          grid-column: span 6;

          & h1,
          & h2 {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 40px;

  margin: 70px 0;

  @media (max-width: 768px) {
    margin: 40px 0;
  }
`;

const GetInvolved = ({ data }) => {
  const [pageType, setPageType] = useContext(PageType);

  useEffect(() => {
    setPageType(`get-involved`);
  }, [data]);

  if (data.prismicGetInvolved === null) return <></>;

  const content = data.prismicGetInvolved.data.body.map((content, index) => {
    if (content.slice_type === "text") {
      return (
        <Grid key={`text_${index}_${content.id}`} className="text">
          <div className="text-container">
            <div className="inner-text-container">
              <div
                className="section-title"
                dangerouslySetInnerHTML={{
                  __html: content.primary.section_title.html,
                }}
              />
              <div
                className="section-text"
                dangerouslySetInnerHTML={{
                  __html: content.primary.section_text.html,
                }}
              />
            </div>
          </div>
        </Grid>
      );
    }

    if (content.slice_type === "images") {
      return (
        <Grid key={`images_${index}_${content.id}`} className="gallery">
          <Gallery images={content.items} />
        </Grid>
      );
    }

    if (content.slice_type === "image") {
      return (
        <Grid key={`image_${index}_${content.id}`} className="single-image">
          <div className="single-image-container">
            {content.primary.image1.fluid !== null && (
              <img
                // className={ImageOrientation(content.image)}
                srcSet={content.primary.image1.fluid.srcSetWebp}
                src={content.primary.image1.fluid.srcWebp}
                alt={content.primary.image1.alt}
                loading={`lazy`}
              />
            )}
          </div>
        </Grid>
      );
    }

    if (content.slice_type === "video") {
      return (
        <Grid key={`video_${index}_${content.id}`} className="single-video">
          <div className="single-video-container">
            <SingleVideoEmbed content={content} index={index} />
          </div>
        </Grid>
      );
    }
  });

  return (
    <>
      <Helmet
        title={`${data.prismicGetInvolved.data.title.text} | Three Rivers | Bexley`}
        meta={[
          {
            name: "title",
            content: `${data.prismicGetInvolved.data.title.text} | Three Rivers | Bexley`,
          },
          {
            property: "og:title",
            content: `${data.prismicGetInvolved.data.title.text} | Three Rivers | Bexley`,
          },
          {
            property: "twitter:title",
            content: `${data.prismicGetInvolved.data.title.text} | Three Rivers | Bexley`,
          },
        ]}
      />
      <Page>
        <ContentContainer>{content}</ContentContainer>
      </Page>
    </>
  );
};

export default withPreview(GetInvolved);

export const query = graphql`
  {
    prismicGetInvolved {
      _previewable
      data {
        title {
          text
        }
        body {
          ... on PrismicGetInvolvedBodyText {
            id
            slice_type
            primary {
              section_title {
                html
              }
              section_text {
                html
              }
            }
          }
          ... on PrismicGetInvolvedBodyImages {
            id
            slice_type
            items {
              image {
                alt
                dimensions {
                  width
                  height
                }
                fluid {
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
          ... on PrismicGetInvolvedBodyImage {
            id
            slice_type
            primary {
              image1 {
                fluid {
                  srcWebp
                  srcSetWebp
                }
                alt
              }
            }
          }
          ... on PrismicGetInvolvedBodyVideo {
            id
            slice_type
            primary {
              video {
                height
                html
                width
              }
            }
          }
        }
      }
    }
  }
`;
